import * as React from "react";
import { Link } from "gatsby";

// import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
// import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function GermainTowers() {
  return (
    <Layout>
      <Helmet title="Germain Towers | Michael Weslander & Neil Siekman" />

      <h1>Germain Towers</h1>
      <h2>Michael Weslander & Neil Siekman</h2>
      <h4>Key: Em</h4>
      <h4>Tempo: 82</h4>
      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Sitting on my back porch last night</p>
          <p>Wishing for a view</p>
          <p>All I can see are the Germain Towers</p>
          <p>All I can think of is you</p>
        </Verse>

        <Chorus>
          <p>Always thought I was over my head</p>
          <p>None of this dreaming comes true</p>
          <p>18 going on 95</p>
          <p>I'll still be missing you</p>
        </Chorus>

        <Verse>
          <p>Why does the wind blow lonely</p>
          <p>Even the sky knows blue</p>
          <p>Shut back here in this old apartment</p>
          <p>Your perfume still cuts through</p>
        </Verse>

        <Chorus>
          <p>Always thought I was over my head</p>
          <p>None of this dreaming comes true</p>
          <p>18 going on 95</p>
          <p>I'll still be missing you</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
